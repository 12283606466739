var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: _vm.timer, staticClass: "table-wrapper" },
    [
      _c("list", {
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          searchVisible: _vm.searchVisible,
          hasOperateColumn: _vm.hasOperateColumn,
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }