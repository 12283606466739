<template>
  <div class='CarInfoForm-container'>
    <form-panel :hasHeader="false">
      <ul class="carInfoForm">
        <li v-for="(carsData, index) in cars.data" :key="index">
         <div class="ownerHouseId">
            <span class="title">用户房号：</span>
            <span>{{carsData.address}}</span>
          </div>
          <div class="carInfo">
            <div>
              <span class="required">*</span>
              <span class="title">车牌号：</span>
              <span>{{carsData.carNum}}</span>
            </div>
            <div>
              <span class="required">*</span>
              <span class="title">卡类型：</span>
              <span>{{carsData.cardType}}</span>
            </div>
          </div>
          <div class="carInfo">
            <div>
              <span class="required">*</span>
              <span class="title">停车卡号：</span>
              <span>{{carsData.cardNumSurface}}</span>
            </div>
            <div>
              <span class="required">*</span>
              <span class="title">办理时间：</span>
              <span>{{carsData.handleTime}}</span>
            </div>
          </div>
         <div class="carInfo">
            <div>
              <span class="required">*</span>
              <span class="title">到期时间：</span>
              <span>{{carsData.expireTime}}</span>
            </div>
            <div>
              <span class="required">*</span>
              <span class="title">车辆状态：</span>
              <span>{{carsData.carStatusName}}</span>
            </div>
          </div>
          <div class="editCars" v-if="isShowUpdate" @click="editCars(carsData.id)">编辑该车辆</div>
        </li>
         <div class="createCars" v-if="isShowAddCar" @click="createCars">登记车辆信息</div>
      </ul>
    </form-panel>
    <el-drawer v-if="subPageType == 'drawer'" :wrapperClosable="false" append-to-body  size="80%" destroy-on-close :visible.sync="showDrawer">
      <owner-car-form @closeDrawer="closeSubDrawer" :editQuery='editQuery'/>
    </el-drawer>
  </div>
</template>
<script>
import OwnerCarForm from '../../ownerCar/OwnerCarForm.vue'
export default {
  name: 'CarInfoForm',
  props: ['cars', 'userId', 'houseId'],
  data () {
    return {
      editQuery:{},
      showDrawer:false,
      isShowUpdate: false,
      isShowAddCar: false
    }
  },
  components:{
    OwnerCarForm
  },
  computed: {
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
  },
  created () {
    this.controlPermission()
  },
  methods: {
    closeSubDrawer(){
      this.showDrawer =false
      this.$emit('contact',{
        method:'getDetailData',
        data:() => {
          this.showComponent = true
        }
      })
    },
    // 控制数据的显隐
    controlPermission () {
      const update = Number(this.$store.state.permission.update)
      const addCar = Number(this.$store.state.permission.addCar)
      this.isShowUpdate = update === 1
      this.isShowAddCar = addCar === 1
    },
    editCars (id) {
      if(this.subPageType == 'drawer'){
        this.editQuery = {id}
        this.showDrawer =true
      }else{
        this.$router.push({
          name: 'ownerCarForm',
          query: {
            id: id
          }
        })
      }
    },
    createCars () {
      if(this.subPageType == 'drawer'){
        this.editQuery = {
          userId: this.userId.id,
          houseId: this.houseId.id
        }
        this.showDrawer =true
      }else{
        this.$router.push({
          name: 'ownerCarForm',
          query: {
            userId: this.userId.id,
            houseId: this.houseId.id
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.CarInfoForm-container{
  height: 100%;
}
.carInfoForm{
  width:100%;
  .ownerHouseId{
      margin-left: 50px;
      margin-bottom: 15PX;
      .title{
        font-size: 14px;
        color: #606266;
        margin-right: 10px;
      }
    }
  .carInfo{
    div{
      display: inline-block;
      margin: 15px 0px 15px 0px;
      word-wrap: break-word;
      .required{
        color: #F56C6C;
      }
      .title{
        font-size: 14px;
        color: #606266;
        margin-right: 5px;
      }
    }
    div:nth-child(2n-1){
      width: 50%;
      margin-left: 48px;
    }
  }
  .editCars{
    border: 1px solid #1B8CF2;
    border-radius: 4px;
    background: #1B8CF2;
    color: white;
    text-align: center;
    line-height: 32px;
    width: 150px;
    height: 32px;
    margin: 5px 0 18px 64px;
    cursor: pointer;
  }
  .createCars{
    border: 1px solid #1B8CF2;
    border-radius: 4px;
    background: #1B8CF2;
    color: white;
    text-align: center;
    line-height: 32px;
    width: 150px;
    height: 32px;
    margin: 60px 0 15px 50px;
    cursor: pointer;
  }
}
</style>
