<template>
  <div class="table-wrapper" :key="timer">
		<list :searchUrl="searchUrl" :searchParams="searchParams" :headers="headers" :searchVisible="searchVisible" :hasOperateColumn="hasOperateColumn">
			<template #headerSlot>
        <!-- <v-button text="返回" @click="returnClick" /> -->
      </template>
		</list>
	</div>
</template>

<script>
import { getLogInfoUrl } from './api'
export default {
	name: 'LogInfoForm',
	props:{
		'query':{},
		'hasOperateColumn':{
			default:false
		},
		searchVisible:{
			default:false
		}
	},
	data () {
		return {
			timer: '',
			searchUrl: getLogInfoUrl,
			searchParams: {
				userId: ''
			},
			headers: [ {
				prop: 'infoType',
				label: '修改类型',
				width: 100
			}, {
				prop: 'intime',
				label: '修改时间',
				width: 200
			}, {
				prop: 'inuserName',
				label: '操作人',
				width: 100
			}, {
				prop: 'remarks',
				label: '修改信息'
			}],
			tableData: []
		}
	},
	computed: {
		subPageType() {
			return this.$store.state.theme['sub-page-type'] || 'inner'
		},
		queryReal(){
			return  this.subPageType == 'drawer' ? this.query : this.$route.query
		},
		type () {
			return {
				type: this.queryReal.type
			}
		}
	},
	mounted () {
		this.handleGetLogInfo()
	},
	activated () {
		// 刷新表格数据
		this.timer = new Date().getTime()
	},
	methods: {
		// 获取管理员操作日志
		async handleGetLogInfo () {
			const userId = this.queryReal.id
			this.searchParams.userId = userId
		},

		returnClick () {
			let type = Number(this.type.type)
      if (type === 0) {
        this.$router.push({
          name: 'serverOwnerAction!getAllOwnerList.action2'
        })
      } else {
        this.$router.push({
          name: 'serverOwnerAction!getAllOwnerList.action1'
        })
      }
		}
	}
}
</script>

<style scoped lang="scss">
.table-wrapper {
	width: 90%;
	margin-left: 20px;
	overflow: scroll;
}
</style>